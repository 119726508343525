/* Variables overrides */
$primary: #0078ad;
$secondary:darken(#f8b334, 10%);
$tertiary: $primary;
$border-color:  #c3dcf0;

$body-color:#222;
$headings-color: $tertiary;
$link-color: $secondary;
$breadcrumb-text-color: #333;

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$body-bg: #f7f7f7;

$search-home-bg-color: $border-color;
$search-overview-bg-color: $border-color;

$mobile-header-background: white;
$mobile-header-color: $primary;

$top_strip_color: $border-color;
$top_strip_text_color: $body-color;

$navbar-dark-color: $tertiary;
$navbar-dark-active-color: $secondary;
$navbar-dark-hover-color: $secondary;

$footer-background-color: $border-color;
$bottom-footer-bg-color: $border-color;
$footer-text-color-header: $body-color;
$footer-text-color: $body-color;
$footer-border-top: 1px solid $white;

$border-radius: 4px;

@import url('https://fonts.googleapis.com/css?family=Droid+Sans');
$font-family-sans-serif: 'Droid Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$font-family-base: 'Droid Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$btn-font-family: 'Droid Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;
$headings-font-family: 'Droid Sans', Arial, Helvetica, "Nimbus Sans L", sans-serif;

/* Flean base imports */
@import "../../flean_base_2019/sass/imports";


/* Extra overrides */
#header > .header.header-top-strip .top .container {
  justify-content: flex-end;
}
#header > .header.header-top-strip .top .container a {
  &:last-child {
    margin-right: -$nav-link-padding-x;
  }
  &:hover {
    text-decoration: underline;
  }
}

@include media-breakpoint-up(xl) {
  #header > .header.header-top-strip .main .container .logo img {
    max-height: 50px;
    max-width: 286px;
  }
}
#header > .header nav .nav-link {
  font-size: 1.125rem;
}
.node-type-overview-page #block-helper-search-search-holiday-homes h2 {
  color: $primary;
  font-weight: 700;
}

.discounts-custom{
  .inner {
    > div {
      position:absolute;
      z-index: 1;
      top: -7px;
      left: -10px;
      height: 73px;
      width: 193px;
      background-size: contain !important;
      background-repeat: no-repeat !important;
      z-index: 0;
      .column & {
        top: 65px;
      }
      .page-blocks .column & {
        top: -20px;
        left: -20px;
      }
      @include media-breakpoint-down(lg) {
        width: 193px;
        top: -7px;
        left: -10px;
        .column & {
          top: 5px;
          left: auto;
          right: 0;
          width: 80px;
        }
        .page-blocks .column & {
          top: 5px;
          left: auto;
          right: 5px;
          width: 80px;
        }
      }
      &.nl {
        background:url(../images/aanbieding.png);
      }
      &.de {
        background:url(../images/angebot.png);
      }
      &.en {
        background:url(../images/on-offer.png);
      }
      &.last_minute.de,
      &.last_minute.nl {
        background:url(../images/last-minute.png);
      }
    }
  }
}

.page-blocks {
  > .column {
    &.block_html {
      @include media-breakpoint-down(md) {
        .image {
          display: block;
          margin-bottom: 0.5rem;
        }
      }
    }
    &.block-last_minute {

    }
  }
}
